/**
 * TargetDurationStatsModel
 *
 * Partial object of the global stats
 * TODO find out what the real object looks like
 */
import { DiabetesParameters } from '@/models/DiabetesDataModel';

export type GlycemiaDistributionRange = {
  glycemia_range: string;
  ratio: number;
};

type TargetDurationStatsCommon = {
  high_glycemia: number;
  target_glycemia: number;
  low_glycemia: number;
  parameters: DiabetesParameters;
  glycemia_distribution: GlycemiaDistributionRange[];
};

export type TargetDurationStats = {
  reader: TargetDurationStatsCommon & {
    total_glycemia: number;
  };
  sensor: TargetDurationStatsCommon & {
    total_time: number;
  };
};

export type TargetDurationStatsKeys = keyof TargetDurationStats;
export type TargetDurationStatsValues =
  TargetDurationStats[TargetDurationStatsKeys];

export const targetDurationStatsDecoder = (
  value: unknown,
): TargetDurationStats => value as TargetDurationStats;

/*
export const targetDurationStatsDecoder: DecoderFunction<TargetDurationStats> =
  recordWithContext('TargetDurationStats', {
    reader: record({
      glycemia_distribution: nullOrUndef((value: any) => value),
      high_glycemia: nullOrUndef(number),
      low_glycemia: nullOrUndef(number),
      parameters: record({
        patient: nullOrUndef(string),
        thresholdHyperglycemia: nullOrUndef(number),
        thresholdHyperglycemiaSevere: nullOrUndef(number),
        thresholdHypoglycemia: nullOrUndef(number),
        thresholdHypoglycemiaSevere: nullOrUndef(number),
      }),
      target_glycemia: nullOrUndef(number),
      total_glycemia: nullOrUndef(number),
      very_high_glycemia: nullOrUndef(number),
      very_low_glycemia: nullOrUndef(number),
    }),
    sensor: record({
      glycemia_distribution: nullOrUndef((value: any) => value),
      high_glycemia: nullOrUndef(number),
      low_glycemia: nullOrUndef(number),
      parameters: record({
        patient: nullOrUndef(string),
        thresholdHyperglycemia: nullOrUndef(number),
        thresholdHyperglycemiaSevere: nullOrUndef(number),
        thresholdHypoglycemia: nullOrUndef(number),
        thresholdHypoglycemiaSevere: nullOrUndef(number),
      }),
      target_glycemia: nullOrUndef(number),
      total_time: nullOrUndef(number),
      very_high_glycemia: nullOrUndef(number),
      very_low_glycemia: nullOrUndef(number),
    }),
  });
*/
